import VerticalLines from "../components/VerticalLines"

const LandingPage = () => {
  return (
    <div className="tww-nav">
      <VerticalLines full="full" white="white"/>
      <div className="landing-right">
        <img src="images/the_warehouse.svg" alt="The Warehouse Drawing" />
      </div>
      <div className="landing">
        <div class="banner">
          <div class="banner-inner">
            <h1>London Festival Orchestra</h1>
            <hr/>
            <h1>The Warehouse Waterloo</h1>
            <h3><i class="fa fa-hammer"></i> Website coming soon... <i class="fa fa-hammer"></i></h3>
          </div>
          <a href="mailto:the.warehouse@lfo.co.uk">Contact Us</a>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
