import React from "react";
import "./stylesheets/App.scss";
import { Route, Switch } from "react-router-dom";
import 'mapbox-gl/dist/mapbox-gl.css';

// import LfoPage from "./pages/LfoPage";
// import ShopPage from "./pages/ShopPage";
import LandingPage from "./pages/LandingPage";
// import HomePage from "./pages/HomePage";
// import ProfilePage from "./pages/ProfilePage";
// import StudiosPage from "./pages/StudiosPage";
// import InstrumentAlbumPage from "./components/Instruments/InstrumentAlbumPage";
// import NavbarTWW from "./components/NavbarTWW";
// import FooterW from "./components/FooterW";
// import FooterLfo from "./components/FooterLfo";
// import GalleryW from "./components/Gallery/GalleryW";
// import GalleryLfo from "./components/Gallery/GalleryLfo";
// import NavbarLF from "./components/NavbarLF";

function App() {
  return (
    <>
      <Switch>
        <Route path="/" exact>
          <LandingPage />
        </Route>
      </Switch>
    </>
  );
}

export default App;
