const VerticalLines = (props) => {
  return (
    <div className={"vlines " + (props.full ? props.full : '')}>
      <div className={props.white === "white" ? "white vl" : "black vl"}></div>
      <div className={props.white === "white" ? "white vl" : "black vl"}></div>
      <div className={props.white === "white" ? "white vl" : "black vl"}></div>
      <div className={props.white === "white" ? "white vl" : "black vl"}></div>
      <div className={props.white === "white" ? "white vl" : "black vl"}></div>
    </div>
  )
}

export default VerticalLines;
